import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>
                    Formació d'Entrevista Grupal per Competències (21 d'abril 10:00 - 15:00 h)
                </Title>
                <Text>
                    Us presentem un curs únic i supernecessari per preparar-les competències de
                    l'entrevista de Mossos d'Esquadra 2024 de forma grupal
                    <br />
                    <br />
                    Parlar en públic és la millor tècnica d'aprenentatge per sortir de la zona de
                    confort.
                    <br />
                    <br />
                    A iOpos Acadèmia som especialistes en preparació d'entrevista i som conscients
                    de la importància de les competències a l'entrevista, per aquest motiu volem
                    presentar-te aquest curs.
                    <br />
                    <br />
                    El curs és impartit per un psicòleg especialista en selecció amb més de 35 anys
                    d'experiència.
                    <br />
                    <br />
                    La duració del curs són 5 hores presencials.
                    <br />
                    <br />
                    La primera hora del curs serà teòrica, el nostre psicòleg us explicarà les 10
                    competències amb exemples per poder assimilar-les millor i interioritzar-les.
                    <br />
                    <br />
                    Prèviament al curs cada alumne haurà de preparar les 10 competències mitjançant
                    un document que iOpos Acadèmia us facilitarà.
                    <br />
                    <br />
                    Durant les 4 hores següents:
                    <br />
                    <br />
                    -Mitjançant una bateria de preguntes molt amplia cada alumne haurà de defensar
                    una competència en públic.
                    <br />
                    <br />
                    - Cada alumne sortirà aleatòriament 2 o 3 cops
                    <br />
                    <br />
                    - La resta de companys farà de tribunal avaluador
                    <br />
                    <br />
                    - En finalitzar s'entregarà a cada alumne un informe avaluador de les
                    competències treballades.
                    <br />
                    <br />
                    Serà un curs molt dinàmic on cada alumne aprendrà del psicòleg, dels companys i
                    d'ell mateix.
                    <br />
                    <br />
                    A més us avancem que al curs vindrà un convidat/a sorpresa!!
                    <br />
                    <br />
                    El curs es realitza el diumenge 21 d'abril de 10.00 a 15.00 h
                    <br />
                    <br />
                    Al c/ Martí Codolar núm 18, Hospitalet de Llobregat
                    <br />
                    <br />
                    Preu: 59,99 euros
                    <br />
                    <br />
                    Al curs es valorarà les competències següents:
                    <br />
                    <br />
                    - Responsabilitat i orientació a la qualitat
                    <br />
                    <br />
                    - Cooperació i treball en equip
                    <br />
                    <br />
                    - Autonomia i iniciativa
                    <br />
                    <br />
                    - Resolució de problemes
                    <br />
                    <br />
                    - Orientació de servei a les persones
                    <br />
                    <br />
                    - Adaptabilitat i flexibilitat
                    <br />
                    <br />
                    - Autocontrol i resistència a la pressió,
                    <br />
                    <br />
                    - Autogestió i desenvolupament personal,
                    <br />
                    <br />
                    - Motivació i identificació amb l'organització
                    <br />
                    <br />- Habilitats socials i comunicatives.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/-yYf--WIgYQ"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
